import { defineStore } from 'pinia';
import taskApi from '../api/tasks';
import favoriteApi from '../api/favorite';
import TodoApi from '../api/todo';
import { uuid } from 'vue-uuid';
import dayjs from 'dayjs';
import { toRaw } from 'vue';
import { useWorkflowStore } from './workflowStore';
import { useUserStore } from './userStore';
import { useBadgesStore } from './badgesStore';
import { useTrackingStore } from '@/stores/trackingStore';
import { hasProperty } from '../helpers/common';

export const useTasksStore = defineStore('tasks', {
    state: () => ({
        tasks: null,
        projects: [],
        entityId: null,
        dataFullLength: 0,
        requestLimit: 0,
        requestOffset: 0
    }),

    getters: {
        tasksVsBadges: state => {
            const badgesStore = useBadgesStore();
            const hasBadge = task => {
                return badgesStore.tasksBadges?.filter(el => el.instance_id === task.id);
            };
            return state.tasks?.map(el => {
                const taskBadge = hasBadge(el);
                if (taskBadge && taskBadge.length > 0) {
                    el['has_badges'] = taskBadge.map(badge => badge?.type);
                } else {
                    el['has_badges'] = [];
                }
                return el;
            });
        },
        getTaskById: state => {
            if (state.tasks) {
                return taskId => state.tasks.find(task => task.id === taskId);
            } else {
                console.log('no task in store');
                return {};
            }
        },
        getProjectById: state => {
            if (state.projects) {
                return projectId => state.projects.find(project => project.id === projectId);
            } else {
                console.warn('no project in store');
            }
        },
        getTaskEntityId: () => {
            const userStore = useUserStore();
            const res = userStore.getEnvironmentByName('crm_task_id');
            useTasksStore.entityId = res;
            return res;
        },
        getProjectEntityId: () => {
            const userStore = useUserStore();
            const res = userStore.getEnvironmentByName('crm_project_group_id');
            return res;
        }
    },
    actions: {
        async getAllTasks() {
            console.log('getAllTasks');
            this.tasks = null;
            this.tasks = await taskApi.getTasks();
        },
        async getTasksByFilters(filter = {}) {
            this.tasks = await taskApi.getTasks(filter);
        },
        findTask(id) {
            return this.tasks?.find(el => el.id === id);
        },
        addTaskToStore(task) {
            // console.log("addTaskToStore");
            if (this.tasks) {
                this.tasks.push(task);
            } else {
                this.tasks = [task];
            }
        },
        async getTaskBy(id) {
            const loadById = async id => {
                return await taskApi.getTasksById(id);
            };

            if (this.tasks) {
                let task;
                task = this.tasks.find(task => task.id === id);
                if (task) {
                    return task;
                } else {
                    task = await loadById(id);
                    // this.addTaskToStore(task);
                    return task;
                }
            } else {
                let task = await loadById(id);
                // this.addTaskToStore(task);
                return task;
            }
        },
        async filterTask(params) {
            this.dataFullLength = 0;
            let res = await taskApi.filterTask(params);
            if (params?.limit) {
                // this.requestLimit = params?.limit;
                let dataFullLengthObg = await taskApi.getFiltreadTasksLength(params);
                if (dataFullLengthObg?.count) {
                    this.dataFullLength = dataFullLengthObg?.count;
                }
            }
            if (res && params?.limit && this.dataFullLength) {
                if (res?.length === this.dataFullLength) {
                    this.requestOffset = 0;
                } else {
                    this.requestOffset = res.length;
                    if (this.dataFullLength - res.length < 300) {
                        this.loadTaskVsOffset(params, false);
                    } else {
                        this.requestLimit = 300;
                    }
                }
            }
            if (res?.length >= 0) {
                this.tasks = res;
                // console.log(res?.length);
            }
        },
        async loadTaskVsOffset(params, loader = true) {
            if (this.requestOffset === 0) return;
            params.limit = this.requestLimit;
            params.offset = this.requestOffset;
            let res = await taskApi.filterTask(params, loader);
            if (Array.isArray(this.tasks) && res?.length > 0) {
                this.tasks = this.tasks.concat(res);

                if (this.tasks.length === this.dataFullLength) {
                    this.requestOffset = 0;
                } else {
                    this.requestOffset = this.requestOffset + this.requestLimit;
                }
                console.log(res.length);
            } else {
                this.requestOffset = 0;
            }
        },
        async updateTask(taskId, data, updateRemote = true, onError = () => {}) {
            this.tasks?.forEach(task => {
                if (task.id == taskId) {
                    for (let key in data) {
                        task[key] = data[key];
                    }
                    task.version++;
                }
            });
            if (updateRemote) {
                let requestData = { ...data };
                if (hasProperty(data, 'status')) {
                    delete requestData.status;
                }
                return await taskApi.updateTasks(taskId, requestData, onError);
            }

            return true;
        },
        async syncTaskData(id) {
            let newTaskData = await taskApi.getTasksById(id);
            if (newTaskData) {
                if (this.tasks?.length > 0) {
                    let taskObg = this.tasks.find(el => el.id === id);
                    if (taskObg) {
                        taskObg.participants = newTaskData?.participants;
                        taskObg.auditors = newTaskData?.auditors;
                        taskObg.workflow = newTaskData?.workflow;
                        taskObg.approve_id = newTaskData?.approve_id;
                        taskObg.approved = newTaskData?.approved;
                        taskObg.parent_id = newTaskData?.parent_id;
                        taskObg.parent = newTaskData?.parent;
                        taskObg.created_time = newTaskData?.created_time;
                        taskObg.has_children = newTaskData?.has_children;
                        taskObg.has_comments = newTaskData?.has_comments;
                        taskObg.has_files = newTaskData?.has_files;
                        taskObg.description = newTaskData?.description;
                        taskObg.stage_id = newTaskData?.stage_id;
                        taskObg.stage = newTaskData?.stage;
                        taskObg.status = newTaskData?.status;
                        taskObg.permissions = newTaskData?.permissions;
                        taskObg.checklists_counter = newTaskData?.checklists_counter;
                        taskObg.children_count = newTaskData?.children_count;
                        return newTaskData;
                    } else {
                        // taskObg = await this.getTaskBy(id);
                        this.addTaskToStore(newTaskData);
                        return newTaskData;
                    }
                } else {
                    this.addTaskToStore(newTaskData);
                    return newTaskData;
                }
            } else {
                console.log('no task in server');
                return null;
            }
        },
        async getFavoriteTasks() {
            const userStore = useUserStore();
            let res = await favoriteApi.getEntityFavorite(userStore.getEnvironmentByName('crm_task_id'));
            this.tasks = res;
            return res;
        },
        async getTasksInTodo() {
            const userStore = useUserStore();
            let res = await TodoApi.getEntityTodo(userStore.getEnvironmentByName('crm_task_id'));
            this.tasks = res;
        },
        async getTaskVsPin(priority, showLoader = true) {
            console.log('getTaskVsPin');
            let parametrs = `with_notification_type=${priority}`;
            let res = await taskApi.filterTask('', parametrs, showLoader);
            this.tasks = res;
        },
        async loadTasksByPin(pin) {
            if (this.tasks) {
                let find = this.tasks.find(el => el.id === pin.instance_id);
                if (!find) {
                    let task = await this.getTaskBy(pin.instance_id);
                    this.addTaskToStore(task);
                }
            } else {
                let task = await this.getTaskBy(pin.instance_id);
                this.addTaskToStore(task);
            }
            console.log('loadTasksByPin', pin);
        },
        async getTaskGroups() {
            const params = {
                fields: {
                    id: {},
                    name: {},
                    parent_id: {},
                    workflow_id: {},
                    is_project: {},
                    order: {},
                    version: {}
                }
            };
            if (this.projects?.length <= 0) {
                let res = await taskApi.getTaskProgectsVsParams(params);
                this.projects = res;
            }
        },
        async getProjectPropertyById(id, property) {
            let project = this.projects?.find(el => el.id === id);

            if (!project || !project?.[property]) {
                const fetchedProject = await taskApi.getProjectById(id);
                if (!fetchedProject) return null;

                this.projects = this.projects.map(project => (project.id === id ? fetchedProject : project));
                return fetchedProject[property];
            }

            return project?.[property] || null;
        },
        async getTaskGroupById(groupId) {
            let existGroup = this.projects?.find(el => el.id === groupId);
            if (existGroup) {
                return existGroup;
            } else return await taskApi.getProjectById(groupId);
        },
        generateNewTask(options) {
            const workflowStore = useWorkflowStore();
            const userStore = useUserStore();
            let task = {
                id: uuid.v4(),
                name: '',
                description: '',
                parent_id: null,
                participants: [],
                auditors: [],
                responsible: null,
                stage: toRaw(workflowStore.newTaskStage),
                start_date: null,
                end_date: null,
                duration: null,
                project: null,
                // groups: [],
                version: 1,
                workflow: workflowStore.defaultWorkflow,
                created_by: toRaw(userStore.user),
                created_time: dayjs().unix() * 1000,
                last_edited_by: toRaw(userStore.user),
                last_edited_time: dayjs().unix() * 1000
            };
            if (options) {
                for (let key in options) {
                    task[key] = options[key];
                }
            }
            return task;
        },
        async saveNewTask(task) {
            let newTaskObj = await taskApi.createTask(task);
            if (newTaskObj) {
                if (Array.isArray(this.tasks)) this.tasks.unshift(task);
                else this.tasks = [task];
                return newTaskObj;
            } else {
                return null;
            }
        },
        async deleteTask(taskId) {
            const index = this.tasks.findIndex(({ id }) => id == taskId);
            if (index >= 0) {
                this.tasks.splice(index, 1);
                let res = await taskApi.deleteTask(taskId);
                return res;
            }
        },
        removeTaskFromLocalStore(taskId) {
            const index = this.tasks.findIndex(({ id }) => id == taskId);
            if (index >= 0) {
                this.tasks.splice(index, 1);
                return true;
            }
        },
        clearTasksStore() {
            this.tasks = [];
        },
        generateNewProject(options) {
            const workflowStore = useWorkflowStore();
            const userStore = useUserStore();
            let project = {
                id: uuid.v4(),
                name: '',
                description: '',
                parent_id: null,
                is_project: true,
                participants: [],
                auditors: [],
                responsible: null,
                start_date: null,
                end_date: null,
                duration: null,
                version: 1,
                workflow: workflowStore.workflows[0],
                created_by_id: toRaw(userStore.user.id),
                created_time: dayjs().unix() * 1000,
                last_edited_by_id: toRaw(userStore.user.id),
                last_edited_time: dayjs().unix() * 1000
            };
            if (options) {
                for (let key in options) {
                    project[key] = options[key];
                }
            }
            return project;
        },
        async saveNewProject(project) {
            this.projects.unshift(project);
            return await taskApi.createProject(project);
        },
        async syncProjectData(id) {
            let newProjectData = await taskApi.getProjectById(id);
            if (newProjectData) {
                let projectIndex = this.projects.findIndex(el => el.id == id);
                if (projectIndex > -1) {
                    this.projects[projectIndex] = newProjectData;
                } else this.projects.push(newProjectData);
            }
        },
        async updateProject(id, data) {
            let project = this.projects.find(el => el.id == id);
            if (project) {
                for (let key in data) {
                    project[key] = data[key];
                }
            }
            let res = await taskApi.updateProject(id, data);
            return res;
        },
        async deleteProject(id) {
            const index = this.projects.findIndex(el => el.id === id);
            if (index >= 0) {
                this.projects.splice(index, 1);
                let res = await taskApi.deleteProject(id);
                return res;
            }
        },
        async updateTaskByWS(data) {
            if (data?.id && Array.isArray(this.tasks)) {
                let targetTask = this.tasks.find(el => el.id === data.id);
                if (targetTask && targetTask?.version != data?.version) {
                    for (let key in data) {
                        if (key != 'id' && key != 'last_edited_time' && key != 'description' && key != 'entity_id' && key != 'instance_id') {
                            targetTask[key] = data[key];
                        }
                        if (key === 'stage') {
                            targetTask['status'] = data[key]['status'];
                        }
                    }
                }
            }
            if (data?.id && data?.stage?.status) {
                const status = data?.stage?.status;
                const stopStatusArr = ['completed', 'deferred', 'cancelled'];
                if (!stopStatusArr.includes(status)) return;

                const trackingStore = useTrackingStore();
                trackingStore.stopTrackingCurrentTask(data?.id);
            }
        },
        addTaskByWS(task) {
            if (!this.findTask(task?.id) && Array.isArray(this.tasks)) {
                this.tasks.push(task);
            }
        },
        deleteTaskByWS(taskId) {
            this.tasks = this.tasks.filter(el => el.id !== taskId);
        },
        async removeTasksFromFavorite(taskId) {
            if (!this.entityId) {
                const userStore = useUserStore();
                this.entityId = userStore.getEnvironmentByName('crm_task_id');
            }
            const favorite = await favoriteApi.checkIfInstanseInFavorite(this.entityId, taskId);
            const favoriteId = favorite.id;
            favoriteApi.removeFromFavorite(favoriteId);
        },
        addTaskToFavorite(taskId) {
            const userStore = useUserStore();
            let favorite = {
                id: uuid.v4(),
                entity_id: userStore.getEnvironmentByName('crm_task_id'),
                instance_id: taskId,
                user_id: toRaw(userStore.user.id)
            };
            favoriteApi.addToFavorite(favorite);
        },

        async updateProjectByWS(project, userId) {
            if (Array.isArray(this.projects)) {
                const targetProjectIndex = this.projects.findIndex(el => el.id === project.id);

                if (targetProjectIndex > -1) {
                    const isUserInvolved =
                        project.responsible_id === userId ||
                        project.default_responsible_id === userId ||
                        project.participants.some(participant => participant.id === userId) ||
                        project.moderators.some(moderator => moderator.id === userId) ||
                        project.auditors.some(auditor => auditor.id === userId);

                    if (isUserInvolved) {
                        this.projects[targetProjectIndex] = {
                            ...this.projects[targetProjectIndex],
                            ...project
                        };
                    } else if (!isUserInvolved && project.created_by_id !== userId) {
                        this.projects.splice(targetProjectIndex, 1);
                    }
                } else {
                    this.projects.push(project);
                }
            }
            console.log('syncProjectByWS', project);
        }
    }
});
