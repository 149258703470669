import { sendRequest } from '@/helpers';
let requestFields = {
    id: {},
    name: {},
    responsible: {
        id: {},
        name: {},
        photo_url: {},
        work_position: {}
        // first_name:{},
        // last_name:{}
    },
    created_by: {
        id: {},
        name: {},
        photo_url: {}
    },
    stage: {
        id: {},
        name: {},
        workflow_id: {},
        color: {}
    },
    groups: {
        id: {},
        name: {}
    },
    status: {},
    disable_notifications: {},
    has_children: {},
    start_date: {},
    created_time: {},
    end_date: {},
    duration: {},
    parent_id: {},
    thumbnail_url: {},
    children_count: {},
    version: {},
    priority: {}
};
export default {
    // async getEntityFavorite(entityId) {
    //   return await sendRequest(
    //     `/api/favorite/list/${entityId}`,
    //     "get",
    //     "",
    //     null,
    //     true
    //   );
    // },
    async getEntityFavorite(entityId) {
        let url = `/api/instances/search/${entityId}`;
        let requestParams = {
            filter: {
                operator: 'and',
                type: 'conjunction',
                conditions: [{ field: '#favorites', comparator: '=', value: 'true', type: 'condition' }]
            },
            fields: requestFields
        };
        return await sendRequest(url, 'post', requestParams, null, true, entityId);
    },
    async addToFavorite(favoriteInstance) {
        return await sendRequest(`/api/favorite/`, 'post', favoriteInstance);
    },
    async checkIfInstanseInFavorite(entityId, instanceId) {
        return await sendRequest(`/api/favorite/is/${entityId}/${instanceId}`, 'get', '');
    },
    async removeFromFavorite(favoriteId) {
        return await sendRequest(`/api/favorite/${favoriteId}`, 'delete', '');
    }
};
