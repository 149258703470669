<template>
    <div>
        <BaseModalWindow
            v-if="dialog?.title"
            class="z-100 bg-[#252525D9]"
            blurBackgrnd
            :horizontalAlign="dialog?.horizontalAlign"
            :verticalAlign="dialog?.verticalAlign"
            @closeModalWindow="closeModal">
            <div class="divide-y divide-[#EAEBEE]">
                <div class="flex flex-col justify-center py-6 px-8 relative w-[337px] box-content">
                    <img
                        v-if="dialog?.dialogType === 'removing'"
                        src="@/assets/images/Basket_perspective_matte.png"
                        alt="basket"
                        class="self-center mt-5 mb-2.5 w-[100px] h-[100px]" />
                    <img v-if="dialog?.dialogType === 'notice'" src="@/assets/images/Bell_perspective_matte.png" alt="bell" class="self-center mt-5 mb-2.5 w-[100px] h-[100px]" />

                    <div class="flex flex-col justify-center">
                        <span class="text-xl leading-6 font-semibold text-center text-[#131416]">
                            {{ dialog.title }}
                        </span>
                        <div
                            class="flex flex-col pt-2.5"
                            :class="{
                                'gap-5': dialog.warningContent
                            }">
                            <span v-if="dialog.warningContent" class="text-sm !leading-6 font-normal text-center text-[#FF7C73]">
                                {{ dialog.warningContent }}
                            </span>
                            <span v-if="dialog.informingContent" class="text-sm !leading-6 font-normal text-center text-[#979BB2]">
                                {{ dialog.informingContent }}
                            </span>
                        </div>
                    </div>

                    <div v-if="dialog?.components?.length">
                        <div v-for="(component, index) in dialog.components" :key="index" class="flex flex-col justify-center py-6 px-8 relative">
                            <component :is="component.component" v-bind="component?.props" v-on="component?.events" />
                        </div>
                    </div>

                    <BaseIcon class="absolute top-2 right-2 cursor-pointer h-[24px] text-gray-300" name="close" @click="closeModal" />
                </div>

                <div class="flex flex-wrap justify-center gap-4 py-4 px-8 relative w-[337px] box-content">
                    <BaseButton
                        v-for="(button, index) in dialog.buttons"
                        :key="index"
                        class="px-2 mx-1"
                        style="min-width: 100px"
                        :variant="button?.variant || 'base'"
                        :color="button?.color"
                        :size="button?.size || 'md'"
                        :is-rounded="true"
                        :label="button.text"
                        @click="handleButtonClick(button)">
                        <template v-if="button?.icon" v-slot:icon>
                            <BaseIcon :class="button?.classIcon" :name="button.icon" />
                        </template>
                    </BaseButton>
                </div>
            </div>
        </BaseModalWindow>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, DefineComponent } from 'vue';

import BaseModalWindow from '@/components/ui/BaseModalWindow.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import { ButtonColor, ButtonVariant } from '@/components/ui/baseButton/models/base-button.model';

import icons from '@/icons';

import { useMultiModalFormStore } from '@/stores/multiModalForm';

type TImage = 'removing' | 'notice';

type TButton = {
    text: string;
    value: boolean;
    size?: string;
    variant?: ButtonVariant;
    color?: ButtonColor;
    icon?: keyof typeof icons;
    classIcon?: string;
};

type TDialog = {
    title: string;
    informingContent: string;
    warningContent: string;
    dialogType: TImage;
    buttons: TButton[];
    horizontalAlign: string;
    verticalAlign: string;
    resolve: Function;
    reject: Function;
    components?: DefineComponent[];
};

const multiModalFormStore = useMultiModalFormStore();

const dialog: ComputedRef<TDialog | null> = computed(() => multiModalFormStore.dialog);

const closeModal = (): void => multiModalFormStore.closeDialog();
const handleButtonClick = (button: TButton) => multiModalFormStore.closeDialog(button.value);
</script>
