<template>
    <Teleport :to="props.teleportTo">
        <div class="dynamic-modal-container" :style="{ zIndex: zindex }">
            <div
                class="smart-modal"
                :class="[{ 'smart-modal_animate': animationEnabled }, $attrs.class || '']"
                :style="{
                    transform: `translate(${resultModalDimensions?.left}px, ${resultModalDimensions?.top}px)`,
                    maxHeight: `${maxHeight}px`,
                    maxWidth: `${maxWidth}px`,
                    ...($attrs.style || {})
                }"
                ref="modal"
                :data-modal-created-timestamp="modalCreatedTimestamp">
                <slot></slot>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import { onMounted, computed, onBeforeUnmount, ref, useAttrs } from 'vue';
import { getMaxZIndexInElement } from '@/helpers';

const $attrs = useAttrs() as { class?: string };

interface Props {
    teleportTo?: string;
    target?: HTMLElement | null;
    windowPaddings?: string | number;
    position?: 'target' | 'mouse' | 'static';
    closeOnTargetHide?: boolean;
    overlap?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    teleportTo: '#app',
    target: null,
    windowPaddings: 4,
    position: 'target',
    closeOnTargetHide: true,
    overlap: false
});

const emit = defineEmits<{
    closeModalWindow: [];
    close: [];
    resize: [];
}>();

const modalCreatedTimestamp = performance.now();

const zindex = ref(getMaxZIndexInElement(props.teleportTo));

const targetNode = computed<HTMLElement | null>(() => props.target);
let previousTargetDimensions: { bottom: number; left: number } | null = null;
let currentTargetDimensions: DOMRect | null = null;

const modal = ref<HTMLElement | null>(null);
const resultModalDimensions = ref<{ top: number; left: number } | null>(null);
let previousModalDimensions: { height: number; width: number } | null = null;
let currentModalDimensions: DOMRect | null = null;

const viewportWidth = ref<number>(0);
const viewportHeight = ref<number>(0);

const updateViewportSize = (): void => {
    if (viewportHeight.value !== window.innerHeight) {
        viewportHeight.value = window.innerHeight;
    }
    if (viewportWidth.value !== window.innerWidth) {
        viewportWidth.value = window.innerWidth;
    }
};

const maxWidth = computed(() => viewportWidth.value - 2 * parseInt(String(props.windowPaddings)));
const maxHeight = computed(() => viewportHeight.value - 2 * parseInt(String(props.windowPaddings)));

let animationFrameId: number | null = null;

function setPosition(): void {
    if (targetNode.value) {
        currentTargetDimensions = targetNode.value?.getBoundingClientRect();
    }
    if (modal.value) {
        currentModalDimensions = modal.value?.getBoundingClientRect();
    }

    if (
        modal.value &&
        currentTargetDimensions &&
        (currentTargetDimensions?.bottom !== previousTargetDimensions?.bottom ||
            currentTargetDimensions?.left !== previousTargetDimensions?.left ||
            viewportHeight.value !== window.innerHeight ||
            viewportWidth.value !== window.innerWidth ||
            currentModalDimensions?.height !== previousModalDimensions?.height ||
            currentModalDimensions?.width !== previousModalDimensions?.width)
    ) {
        if (currentTargetDimensions?.bottom !== previousTargetDimensions?.bottom || currentTargetDimensions?.left !== previousTargetDimensions?.left) {
            animationEnabled.value = false;
        } else {
            animationEnabled.value = true;
        }
        updateViewportSize();
        if (props.closeOnTargetHide && targetNode.value && !isNodeVivible(targetNode.value)) {
            emit('close');
        }
        resultModalDimensions.value = calculateModalPosition(targetNode.value);

        if (
            previousModalDimensions &&
            currentModalDimensions &&
            (Math.round(currentModalDimensions?.height) !== Math.round(previousModalDimensions?.height) ||
                Math.round(currentModalDimensions?.width) !== Math.round(previousModalDimensions?.width))
        ) {
            emit('resize');
        }

        previousTargetDimensions = {
            left: currentTargetDimensions?.left,
            bottom: currentTargetDimensions?.bottom
        };
        if (currentModalDimensions) {
            previousModalDimensions = {
                height: currentModalDimensions?.height,
                width: currentModalDimensions?.width
            };
        } else {
            previousModalDimensions = null;
        }
    }
    animationFrameId = requestAnimationFrame(setPosition);
}
function calculateModalPosition(node: HTMLElement | null): { top: number; left: number } | null {
    if (!modal.value || !node) {
        return null;
    }
    const targetDimensions = node?.getBoundingClientRect();
    const realModalHeight = modal.value.getBoundingClientRect().height;
    const realModalWidth = modal.value.getBoundingClientRect().width;

    let visibleTargetPositionBottom: number | null = null;
    let visibleTargetPositionTop: number | null = null;
    let parent: HTMLElement | null = node?.parentElement;
    while (parent) {
        if (['auto', 'scroll', 'hidden'].includes(getComputedStyle(parent).overflowY)) {
            const parentDimensions = parent.getBoundingClientRect();
            if (!visibleTargetPositionBottom && targetDimensions.bottom > parentDimensions.bottom) {
                visibleTargetPositionBottom = parentDimensions.bottom;
            }
            if (!visibleTargetPositionTop && targetDimensions.top < parentDimensions.top) {
                visibleTargetPositionTop = parentDimensions.top;
            }
            if (visibleTargetPositionBottom && visibleTargetPositionTop) {
                break;
            }
        }
        parent = parent.parentElement;
    }

    if (!visibleTargetPositionBottom) {
        visibleTargetPositionBottom = targetDimensions.bottom;
    }
    if (!visibleTargetPositionTop) {
        visibleTargetPositionTop = targetDimensions.top;
    }
    const windowPaddingsNumber = parseInt(String(props.windowPaddings));

    const topStartPoint = props.overlap ? visibleTargetPositionTop : visibleTargetPositionBottom + windowPaddingsNumber;

    const modalTop = Math.max(
        windowPaddingsNumber,
        topStartPoint + realModalHeight < viewportHeight.value ? topStartPoint : viewportHeight.value - realModalHeight - windowPaddingsNumber
    );
    const modalLeft = Math.max(
        windowPaddingsNumber,
        targetDimensions.left + realModalWidth + windowPaddingsNumber < viewportWidth.value ? targetDimensions.left : viewportWidth.value - realModalWidth - windowPaddingsNumber
    );
    return { top: modalTop, left: modalLeft };
}

const isMouseDownInside = ref(false);

function isClickInside(event: MouseEvent): boolean {
    let isClickInside = false;
    if (modal.value?.contains(event.target as Node)) {
        isClickInside = true;
    } else {
        let currentElement: Node | null = event.target as Node;
        while (currentElement) {
            const elementTimestamp = parseFloat((currentElement as HTMLElement)?.dataset?.modalCreatedTimestamp ?? '');
            if (elementTimestamp && elementTimestamp > modalCreatedTimestamp) {
                isClickInside = true;
                break;
            }
            currentElement = currentElement.parentElement;
        }
    }
    return isClickInside;
}

function handleMouseDown(event: MouseEvent) {
    isMouseDownInside.value = isClickInside(event);
}

function handleMouseUp(event: MouseEvent) {
    if (!isClickInside(event) && !isMouseDownInside.value) {
        emit('close');
    }
}

function isNodeVivible(node: HTMLElement): boolean {
    const nodeDimensions = node.getBoundingClientRect();
    if (nodeDimensions && (nodeDimensions.bottom <= 0 || nodeDimensions.top >= window.innerHeight || nodeDimensions.right <= 0 || nodeDimensions.left >= window.innerWidth)) {
        return false;
    }

    let parent = node.parentElement;
    while (parent) {
        if (['auto', 'scroll', 'hidden'].includes(getComputedStyle(parent).overflowY) || ['auto', 'scroll', 'hidden'].includes(getComputedStyle(parent).overflowX)) {
            const parentDimensions = parent.getBoundingClientRect();
            if (
                nodeDimensions.bottom <= parentDimensions.top ||
                nodeDimensions.top >= parentDimensions.bottom ||
                nodeDimensions.right <= parentDimensions.left ||
                nodeDimensions.left >= parentDimensions.right
            ) {
                return false;
            }
        }
        parent = parent.parentElement;
    }

    return true;
}

const animationEnabled = ref(false);

onMounted(() => {
    animationFrameId = requestAnimationFrame(setPosition);
    setTimeout(() => {
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
    }, 0);
});

onBeforeUnmount(() => {
    if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
    }
    document.removeEventListener('mousedown', handleMouseDown);
    document.removeEventListener('mouseup', handleMouseUp);
});
</script>

<style lang="scss" scoped>
.dynamic-modal-container {
    position: fixed;
    inset: 0px;
    pointer-events: none;
    overflow: hidden;
}
.smart-modal {
    display: flex;
    position: fixed;
    border-radius: 12px;
    border: 1px solid #eaebee;
    background: #fff;
    pointer-events: auto;
    box-shadow: var(--sp-shadow);
}
.smart-modal_animate {
    transition: transform 0.1s ease-in-out;
}
</style>
