<template>
    <button class="p-button" @click="handleClick" :class="buttonClasses" ref="buttonRef">
        <div class="p-button__content-wrapper">
            <div v-if="props.loading" class="p-button__spinner" v-html="svgLoading"></div>
            <div v-if="$slots.icon" class="p-button__icon">
                <slot name="icon"></slot>
            </div>
            <span v-if="props.label" class="p-button__label">
                {{ props.label }}
            </span>
        </div>
    </button>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, PropType } from 'vue';
import { ButtonColor, ButtonPropertyColors, ButtonVariant } from '@/components/ui/baseButton/models/base-button.model';

const props = defineProps({
    label: String,
    size: {
        type: String,
        default: 'md'
    },
    isActive: {
        type: Boolean,
        default: false
    },
    color: {
        type: String as PropType<ButtonColor>,
        default: 'primary',
        validator: (prop: ButtonColor) => ['primary', 'secondary', 'third', 'danger', 'success', 'warning', 'error', 'dangerReverse'].includes(prop)
    },
    variant: {
        type: String as PropType<ButtonVariant>,
        default: 'base',
        validator: (value: ButtonVariant) => ['base', 'outlined', 'text'].includes(value)
    },
    iconPos: {
        type: String,
        validator: (value: string) => ['right'].includes(value)
    },
    loading: {
        type: Boolean,
        default: false
    },
    rounded: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    paddingX: {
        type: String,
        default: '10px'
    }
});

const emit = defineEmits<{
    (e: 'click', event: MouseEvent): void;
}>();

const buttonClasses = computed(() => [`p-button-${props.variant}`, { 'p-button-loading': props.loading }, { 'p-button-active': props.isActive }]);

function createRipple(event: MouseEvent): void {
    const button = (event.currentTarget as HTMLElement).querySelector('.p-button__content-wrapper') as HTMLElement;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.offsetX - radius}px`;
    circle.style.top = `${event.offsetY - radius}px`;
    circle.classList.add('ripple');

    button.appendChild(circle);

    setTimeout(() => {
        circle.remove();
    }, 600);
}

const handleClick = (event: MouseEvent): void => {
    createRipple(event);
    if (!props.disabled && !props.loading) {
        emit('click', event);
    }
};
const buttonSizes: Record<string, string> = {
    ss: '16px',
    xs: '24px',
    sm: '36px',
    md: '44px',
    lg: '50px'
};

const buttonBaseColors: Record<ButtonColor, ButtonPropertyColors> = {
    primary: {
        text: '#ffffff',
        textHover: '#ffffff',
        base: '#519df5',
        hover: '#478bda',
        active: '#478bda'
    },
    secondary: {
        text: '#519df5',
        textHover: '#ffffff',
        base: '#519df51a',
        hover: '#519df5',
        active: '#478bda'
    },
    third: {
        text: '#979bb2',
        textHover: '#ffffff',
        base: '#eaebee',
        hover: '#c0c2cd',
        active: '#c0c2cd'
    },
    disabled: {
        text: '#979bb2',
        textHover: '#ffffff',
        base: '#eaebee',
        hover: '#c0c2cd',
        active: '#c0c2cd'
    },
    warning: {
        text: '#fbc12e',
        textHover: '#ffffff',
        base: '#fbc12e1a',
        hover: '#fbc12e',
        active: '#e5b334'
    },
    danger: {
        text: '#ff7c73',
        textHover: '#ffffff',
        base: '#ff7c731a',
        hover: '#ff7c73',
        active: '#dc827b'
    },
    dangerReverse: {
        text: '#ffffff',
        base: '#ff7c73',
        textHover: '#ff7c73',
        hover: '#ff7c731a',
        active: '#ff7c7336'
    },
    error: {
        text: '#ff7c73',
        textHover: '#ffffff',
        base: '#ff7c731a',
        hover: '#ff7c73',
        active: '#dc827b'
    },
    success: {
        text: '#ffffff',
        textHover: '#ffffff',
        base: '#55c47b',
        hover: '#5ca976',
        active: '#5ca976'
    }
};

const buttonOutlinedColors: Record<ButtonColor, ButtonPropertyColors> = {
    primary: {
        text: '#519DF5',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    secondary: {
        text: '#519DF5',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    third: {
        text: '#C0C2CD',
        textHover: '#519DF5',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    disabled: {
        text: '#979bb2',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    warning: {
        text: '#fbc12e',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    danger: {
        text: '#ff7c73',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    success: {
        text: '#55C47B',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    },
    error: {
        text: '#ff7c73',
        hover: '#e1e1e72e',
        active: '#e1e1e74d'
    }
};

const buttonSize = computed(() => {
    if (['xs', 'sm', 'md'].includes(props.size)) {
        return buttonSizes[props.size as keyof typeof buttonSizes];
    }
    return (parseInt(props.size) && parseInt(props.size) + 'px') || buttonSizes.sm;
});
const borderRadius = computed(() => (props.rounded ? '9999px' : props.size === 'xs' ? '0.2rem' : '0.75rem'));
const cursor = computed(() => (props.disabled || props.loading ? 'not-allowed' : 'pointer'));
const direction = computed(() => (props.iconPos === 'right' ? 'row-reverse' : 'row'));
const visibility = computed(() => (props.loading ? 'hidden' : 'visible'));

const px = computed(() => (props.label ? `calc(${props.paddingX}` + (props.variant !== 'outlined' ? ' + 1px' : '') + ')' : '0'));
const buttonPropsColor = computed(() => (props.disabled ? 'disabled' : props.color));

const text = computed(() => (props.variant === 'base' ? buttonBaseColors[buttonPropsColor.value].text : buttonOutlinedColors[buttonPropsColor.value].text));
const textHover = computed(() =>
    props.variant === 'base'
        ? buttonBaseColors[buttonPropsColor.value].textHover
        : buttonOutlinedColors[buttonPropsColor.value].textHover || buttonOutlinedColors[buttonPropsColor.value].text
);

const bg = computed(() => (props.variant === 'base' ? buttonBaseColors[buttonPropsColor.value].base : 'transparent'));
const bgHover = computed(() => (props.variant === 'base' ? buttonBaseColors[buttonPropsColor.value].hover : buttonOutlinedColors[buttonPropsColor.value].hover));
const bgActive = computed(() => (props.variant === 'base' ? buttonBaseColors[buttonPropsColor.value].active : buttonOutlinedColors[buttonPropsColor.value].active));

const svgLoading = `<svg width="24" height="24" viewBox="0 0 24 24">
<path d="M2 12C2 6.47715 6.47715 2 12 2C17.5229 2 22 6.47715 22 12C22 12.8622 21.8907 13.7 21.6848 14.4998C21.3567 15.7746 20.0007 16.2749 18.9033 15.8887C17.8176 15.5066 17.4191 14.3271 17.6495 13.3793C17.7568 12.9379 17.814 12.4761 17.814 12C17.814 8.78904 15.211 6.18605 12 6.18605C8.78904 6.18605 6.18605 8.78904 6.18605 12C6.18605 15.211 8.78904 17.814 12 17.814C12.7646 17.814 13.493 17.6667 14.1599 17.3997C14.9192 17.0957 15.8833 17.1129 16.607 17.7176C17.1393 18.1624 17.4061 18.8109 17.3691 19.4428C17.3313 20.0868 16.974 20.7092 16.2995 21.0309C14.9963 21.6525 13.5378 22 12 22C6.47715 22 2 17.5229 2 12Z"/>
</svg>`;

const buttonRef = ref<HTMLButtonElement | null>(null);

onMounted(() => {
    setTimeout(() => {
        if (buttonRef?.value) {
            buttonRef.value.style.transition = 'background-color 0.2s, border-color 0.2s, color 0.2s, fill 0.2s';
        }
    }, 0);
});
</script>

<style scoped lang="scss">
.p-button {
    user-select: none;
    overflow: hidden;
    box-sizing: border-box;
    height: v-bind(buttonSize);
    min-height: v-bind(buttonSize);
    max-height: v-bind(buttonSize);
    min-width: v-bind(buttonSize);
    border-radius: v-bind(borderRadius);

    align-items: center;
    cursor: v-bind(cursor);
    & * {
        pointer-events: none;
    }

    background-color: v-bind(bg);
    color: v-bind(text);
    fill: currentColor;

    &.p-button-outlined {
        border-width: 1px;
        border-color: v-bind(text);
        &:hover {
            border-color: v-bind(textHover);
        }
    }

    &.p-button-active,
    &:hover {
        background-color: v-bind(bgHover);
        color: v-bind(textHover);
        fill: currentColor;
    }
    &:active {
        background-color: v-bind(bgActive);
    }

    .p-button__content-wrapper {
        height: 100%;
        position: relative;
        display: flex;
        overflow: hidden;
        gap: 0.25rem;
        padding: 0 v-bind(px);
        flex-direction: v-bind(direction);
        align-items: center;
        justify-content: center;
        font-weight: 500;
        line-height: 24px;

        .p-button__label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .p-button__label,
        .p-button__icon {
            visibility: v-bind(visibility);
        }

        .p-button__spinner {
            position: absolute;
            animation: spin 1s linear infinite;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
<style>
span.ripple {
    pointer-events: none;
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
}
@keyframes ripple {
    to {
        transform: scale(2.5);
        opacity: 0;
    }
}
</style>
