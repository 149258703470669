<template>
    <label class="base-toggle flex items-center w-full py-2.5" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'">
        <span class="switch-label" :class="{ 'mr-2': label }">{{ label }}</span>
        <div class="switch" :class="{ disabled: props.disabled }">
            <input type="checkbox" :disabled="props.disabled" :checked="internalValue" @change="updateValue" />
            <span class="slider" :class="'slider-' + color"></span>
        </div>
    </label>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { uuid } from 'vue-uuid';

const props = defineProps({
    label: String,
    modelValue: {
        type: [Boolean, String, Number],
        default: null
    },
    value: {
        type: [Boolean, String, Number],
        default() {
            return null;
        }
    },
    id: {
        type: String,
        default: () => uuid.v4()
    },
    size: {
        type: Number,
        default: 16
    },
    color: {
        type: String,
        default: 'var(--sp-primary)'
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'toggle-checked', 'change']);
const internalValue = computed(() => props.modelValue || props.value);
const updateValue = () => {
    if (props.disabled) return;
    emit('toggle-checked', !internalValue.value, props.id);
    emit('update:modelValue', !internalValue.value);
    emit('change', !internalValue.value);
};
</script>

<style scoped lang="scss">
/* @import "@/components/ui/baseSwitch/BaseSwitch.scss"; */
.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    min-width: 36px;
    height: 20px;
    padding: 8px 0;
    .switch-label {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        transition: 0.4s;
        border-radius: 34px;
        background-color: #ebedf6;
        &:before {
            position: absolute;
            content: '';
            height: 16px;
            width: 16px;
            left: -2px;
            bottom: -2px;
            background-color: #f9f9f9;
            transition: 0.4s;
            border-radius: 50%;
            box-shadow: 0 1px 2px 0 #00000033;
        }
    }
    input:checked + .slider {
        background-color: #519df5;
        &:before {
            background-color: #f9f9f9;
        }
        &-danger {
            background-color: #ff7c73;
        }
        &-warning {
            background-color: #fbc12e;
        }
        &-success {
            background-color: #55c47b;
        }
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #519df5;
        &-danger {
            box-shadow: 0 0 1px #ff7c73;
        }
        &-warning {
            box-shadow: 0 0 1px #fbc12e;
        }
        &-success {
            box-shadow: 0 0 1px #55c47b;
        }
    }
    input:checked + .slider:before {
        transform: translateX(16px);
    }
    &.disabled {
        .slider {
            background-color: #f9f9f9;
            cursor: not-allowed;
        }
        input:checked + .slider {
            background-color: #adb5bd;
        }
    }
}
</style>
