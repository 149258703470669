<template>
    <div ref="elements" class="context-menu__element flex justify-between gap-1 items-center" @click="handleButtonClick(props.data.id, $event, props.data)">
        <div class="flex items-center gap-1">
            <div
                v-if="props.data.icon && typeof props.data.icon === 'string'"
                class="context-menu__element__icon flex items-center justify-center rounded-full"
                :style="{
                    height: `${props.iconWrapperHeight}px`,
                    width: `${props.iconWrapperHeight}px`,
                    background: props.data.iconBgColor ? props.data.iconBgColor : ''
                }">
                <BaseIcon
                    v-if="props.data.icon"
                    :style="{
                        height: `${props.iconHeight}px`,
                        fill: props.data.iconColor ? props.data.iconColor : ''
                    }"
                    :name="props.data.icon"
                    class="context-menu__element-icon"
                    :class="props.data?.additionalClass"></BaseIcon>
            </div>

            <span class="truncate">{{ props.data.label }}</span>
        </div>

        <button type="button" class="w-6 h-6 text-[--gray2] hover:text-primary">
            <BaseIcon 
                name="arrow_down_24px"
                viewBox="2 2 20 20"
                :class="['append-icon h-[24px]', { 'append-icon-active': unfolded }]"
            />
        </button>
    </div>

    <template v-if="props.data.items">
        <transition-group name="fade">
            <div v-if="unfolded" class="flex flex-col" key="content" :class="`w-[${props.data.width}px]`" :style="{width: `${props.data.width}px` }">
                <template v-for="item in props.data.items" :key="item.id">
                    <div v-if="item.type === 'button'" class="relative">
                        <OptionButton class="!pl-10" :class="{ '!pr-12': item.isSelected }" :data="item"></OptionButton>
                        <div v-if="item.isSelected" class="absolute right-0 top-1/2 -translate-y-1/2 mr-4">
                            <BaseIcon name="tick-02" class="h-[24px] w-[24px] fill-[--primary]"></BaseIcon>
                        </div>
                    </div>
                    <BaseButton 
                        v-if="item.type === 'buttonStyled'" 
                        :color="item.color" 
                        :label="item.label"
                        size="sm"
                        class="ml-14 mr-4"
                        @click.prevent="handleButtonClick(item.id, $event, item)" 
                    ></BaseButton>
                </template>
            </div>
        </transition-group>
    </template>
</template>

<script setup>
import { ref, inject, onMounted, nextTick } from 'vue';

import BaseIcon from '@/components/ui/BaseIcon.vue';
import OptionButton from '@/components/ui/ContextMenu/components/OptionButton.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';

import { InjectionKeyButtonClick } from '../keys.js';

const props = defineProps({
    data: {
        type: Object,
        default: () => ({})
    },
    title: {
        type: String,
        default: ''
    },
    scroll: {
        type: Boolean,
        default: true
    },
    timeParentCreatedForSubMenu: {
        type: Number,
        default: null
    },
    iconHeight: {
        type: Number,
        default: 24
    },
    iconWrapperHeight: {
        type: Number,
        default: 24
    },
    isTop: Boolean
});

const buttonClick = inject(InjectionKeyButtonClick);
const unfolded = ref(false);
const elements = ref(null);

const handleButtonClick = (id, event, object) => {
    unfolded.value = !unfolded.value;

    buttonClick(id, event, object, true, true);

    nextTick(() => {
        elements.value?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
        });
    });
};

onMounted(() => {
    if (props.scroll) {
        nextTick(() => {
            elements.value?.scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
});
</script>

<style lang="scss" scoped>
.append-icon {
    transition: transform 0.2s;
}
.append-icon-active {
    transform: rotate(-180deg);
}
</style>
