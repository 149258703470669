<template>
    <div
        class="context-menu__element flex justify-between gap-1 items-center w-full"
        :class="{
            'context-menu__element_readonly': props.data.readonly,
            '!cursor-not-allowed': props.data.accessForbidden,
            'bg-[#519DF51A]': props.data.id === props.buttonActive || props.data.active,
            top_element: props.isTop,
            'hover-enabled': props.data.moreIconOnHover && !submenuVisible
        }"
        @click="optionClickHandler">
        <div class="flex gap-1 items-center w-full">
            <span
                v-if="props.data.count !== undefined && typeof props.data.count === 'number' && props.data.itemClass"
                class="item__counter text-[13px] text-[#ffffff] text-center font-medium leading-4 rounded-xl mr-2 px-1 min-w-[18px] h-[18px]"
                :class="props.data.itemClass"
                >{{ props.data.count }}</span
            >
            <div
                v-if="props.data.icon && typeof props.data.icon === 'string'"
                class="context-menu__element__icon flex items-center justify-center rounded-full"
                :style="{
                    height: `${props.iconWrapperHeight}px`,
                    width: `${props.iconWrapperHeight}px`,
                    background: props.data.iconBgColor ? props.data.iconBgColor : ''
                }">
                <base-icon
                    v-if="props.data.icon"
                    :style="{
                        height: `${props.iconHeight}px`,
                        fill: props.data.iconColor ? props.data.iconColor : ''
                    }"
                    :name="props.data.icon"
                    :viewBox="props.data.viewBox"
                    class="context-menu__element-icon"
                    :class="props.data?.additionalClass"></base-icon>
            </div>
            <span
                v-if="props.data.label"
                ref="optionTitleRef"
                class="truncate"
                v-tooltip="isTruncated ? props.data.label : ''"
            >
                {{ props.data.label }}
            </span>
        </div>
        <BaseButton
            v-if="props.data.moreIcon || props.data.items"
            class="context-menu__submenu-button"
            data-action="open-submenu"
            variant="text"
            color="third"
            size="xs"
            :rounded="false">
            <template #icon>
                <BaseIcon :name="props.data.moreIcon || 'more-horizontal'" class="h-[24px] w-[24px] pointer-events-none fill-[#C0C2CD]"></BaseIcon>
            </template>
        </BaseButton>
        <ContextMenu
            v-if="props.data.items && submenuVisible"
            :items="props.data.items.map(el => ({ ...el, parentId: props.data.id }))"
            :event="coordinateEvent"
            @changedValue="(...data) => transferData(...data)"
            @close="submenuVisible = false"
            :iconHeight="props.iconHeight" />
    </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ContextMenu from '../ContextMenu.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import { InjectionKeyButtonClick, InjectionKeyTransferData } from '../keys.js';

const submenuVisible = ref(false);
const optionTitleRef = ref();

const transferData = inject(InjectionKeyTransferData);
const buttonClick = inject(InjectionKeyButtonClick);

const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    },
    timeParentCreatedForSubMenu: {
        type: Number,
        default: null
    },
    iconHeight: {
        type: Number,
        default: 24
    },
    iconWrapperHeight: {
        type: Number,
        default: 24
    },
    buttonActive: String,
    isTop: Boolean
});

const isTruncated = computed(() => {
    if (optionTitleRef.value) {
        return optionTitleRef.value.scrollWidth > optionTitleRef.value.clientWidth;
    }
    return false;
})

let coordinateEvent = null;

const handleButtonClick = (id, event, object) => {
    if (event.target?.dataset?.action === 'open-submenu') {
        if (props.data.items) {
            coordinateEvent = event;
            submenuVisible.value = true;
        } else {
            const moreBtnClicked = true;
            buttonClick(id, event, object, moreBtnClicked);
        }
    } else {
        buttonClick(id, event, object);
    }
};

const optionClickHandler = $event => {
    if (props.data.readonly) {
        submenuVisible.value = false;
    } else {
        if (!props.data.accessForbidden) {
            handleButtonClick(props.data.id, $event, props.data);
        }
    }
};
</script>

<style scoped>
.top_element {
    margin-bottom: 5px;
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #dae6f8;
        position: absolute;
        bottom: -5px;
        z-index: 10;
        left: 0;
    }
}
.context-menu__element.hover-enabled .context-menu__submenu-button {
    opacity: 0;
}
.hover-enabled:hover .context-menu__submenu-button {
    opacity: 1;
}
</style>
