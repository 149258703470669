export function getVisibleWidth(element) {
    let rect = element.getBoundingClientRect();
    let parent = element.parentElement;
    let visibleWidth = rect.width;
    while (parent) {
        const parentRect = parent.getBoundingClientRect();
        const style = getComputedStyle(parent);
        if (style.overflow !== 'visible') {
            const overlapLeft = Math.max(0, parentRect.left - rect.left);
            const overlapRight = Math.max(0, rect.right - parentRect.right);

            visibleWidth = Math.max(0, visibleWidth - overlapLeft - overlapRight);
        }

        parent = parent.parentElement;
    }

    return visibleWidth;
}

export function getMaxZIndexInElement(nodeId = '#app') {
    const element = document.querySelector(nodeId);
    if (!element) return 0;
    let maxZIndex = 0;
    function checkZIndex(element) {
        const style = window.getComputedStyle(element);
        const zIndex = style.zIndex;
        const position = style.position;
        if (position !== 'static' && zIndex !== 'auto') {
            const zIndexValue = parseInt(zIndex, 10);
            if (zIndexValue > maxZIndex) {
                maxZIndex = zIndexValue;
            }
            return;
        }
        Array.from(element.children).forEach(child => {
            checkZIndex(child);
        });
    }
    checkZIndex(element);
    return maxZIndex;
}
