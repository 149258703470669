import { BaseComponentSizeModel } from '@/models/base-component-size.model';

const sizeMap: Record<BaseComponentSizeModel, string> = {
    sm: '24px',
    md: '36px',
    lg: '44px'
};

const borderRadiusMap: Record<BaseComponentSizeModel, string> = {
    sm: '6px',
    md: '8px',
    lg: '12px'
};

export const calculateBorderRadius = (size: string | number) => {
    if (typeof size === 'string' && borderRadiusMap[size as BaseComponentSizeModel]) {
        return borderRadiusMap[size as BaseComponentSizeModel];
    }

    let sizeInPx;
    if (typeof size === 'string' && sizeMap[size as BaseComponentSizeModel]) {
        sizeInPx = parseInt(sizeMap[size as BaseComponentSizeModel], 10);
    } else {
        sizeInPx = parseInt(size.toString(), 10);
    }

    return Math.ceil(sizeInPx * 0.2) + 1 + 'px';
};

export const calculateMinFieldHeight = (propsSize: any) => {
    const baseSize = propsSize && parseInt(propsSize) ? `${propsSize}px` : sizeMap[propsSize as BaseComponentSizeModel] || sizeMap.md;
    return baseSize;
};
