import { isUUID } from '@/helpers';
import { uuid } from 'vue-uuid';

export function addOrReplaceBadgeCondition(filterSet, newBadgeCondition) {
    if (!Array.isArray(filterSet)) {
        return;
    }
    if (hasBadgeCondition(filterSet)) {
        let badgeRulesIndex = filterSet.findIndex(el => el.id == 'badges_condition');
        if (!badgeRulesIndex) {
            return;
        }
        if (newBadgeCondition) {
            filterSet.splice(badgeRulesIndex, 1, newBadgeCondition);
        } else {
            filterSet.splice(badgeRulesIndex, 1);
        }
    } else if (newBadgeCondition) {
        filterSet?.push({ ...newBadgeCondition });
    }
}
function hasBadgeCondition(rulesSet) {
    if (!Array.isArray(rulesSet)) {
        return null;
    }
    return rulesSet.find(el => el?.id == 'badges_condition');
}
function findDefaultFilter(filterAlias, listOfFilters) {
    if (!listOfFilters || !Array.isArray(listOfFilters)) return false;
    return listOfFilters?.find(el => el?.alias === filterAlias);
}
function findFilter(filterId, listOfFilters) {
    if (!filterId || !listOfFilters || listOfFilters?.length == 0) {
        return generateFilterObg();
    }
    let searchColumn = isUUID(filterId) ? 'id' : 'alias';
    let exsistFilter = listOfFilters.find(el => el[searchColumn] === filterId);
    return exsistFilter ? exsistFilter : findDefaultFilter('all', listOfFilters);
}

export function generateFilterObg(params = null) {
    let filterObj = {
        id: uuid.v4(),
        name: 'All',
        filter: {
            id: uuid.v4(),
            conditions: [],
            operator: 'and',
            type: 'conjunction'
        },
        temp: true,
        color: '#59D3CC'
    };
    if (params && params == 'hidden') {
        filterObj.hidden = true;
    }
    return filterObj;
}

function generateTagsCondition(tags) {
    let tagsVsHash = tags.split(',').map(el => `#${el}`);
    return {
        comparator: 'in list',
        field: 'tag',
        value: tagsVsHash,
        type: 'condition',
        temp: true
    };
}
function addOrReplaceTagsCondition(filterObj, tagsCondition) {
    if (filterObj?.filter.operator === 'and') {
        let existTagsConditionIndex = filterObj.filter.conditions.findIndex(el => el.field === 'tag' && el.temp);
        if (existTagsConditionIndex === -1) {
            filterObj.filter.conditions.push(tagsCondition);
        } else {
            filterObj.filter.conditions.splice(existTagsConditionIndex, 1, tagsCondition);
        }
    } else {
        let tempConditions = { ...filterObj.filter };
        filterObj.filter = {
            id: uuid.v4(),
            operator: 'and',
            type: 'conjunction',
            conditions: []
        };
        filterObj.filter.conditions.push(tempConditions);
        filterObj.filter.conditions.push(tagsCondition);
    }
}
function removeTagsCondition(filterObj) {
    if (filterObj?.filter && Array.isArray(filterObj.filter?.conditions)) {
        let existTagsConditionIndex = filterObj.filter?.conditions.findIndex(el => el.field === 'tag' && el.temp);
        if (existTagsConditionIndex !== -1) {
            filterObj.filter.conditions.splice(existTagsConditionIndex, 1);
        }
    }
}

function generateFolderCondition(folderId) {
    return {
        comparator: '=',
        field: 'project',
        value: { id: folderId },
        type: 'condition',
        temp: true
    };
}
function addOrReplaceFolderCondition(filterObj, folderCondition) {
    if (filterObj?.filter.operator === 'and') {
        let existFolderConditionIndex = filterObj.filter.conditions.findIndex(el => el.field === 'project' && el.temp);
        if (existFolderConditionIndex === -1) {
            filterObj.filter.conditions.push(folderCondition);
        } else {
            filterObj.filter.conditions.splice(existFolderConditionIndex, 1, folderCondition);
        }
    } else {
        let tempConditions = { ...filterObj.filter };
        filterObj.filter = {
            id: uuid.v4(),
            operator: 'and',
            type: 'conjunction',
            conditions: []
        };
        filterObj.filter.conditions.push(tempConditions);
        filterObj.filter.conditions.push(folderCondition);
    }
}
function removeFolderCondition(filterObj) {
    if (filterObj?.filter && Array.isArray(filterObj.filter?.conditions)) {
        let existFolderConditionIndex = filterObj.filter?.conditions.findIndex(el => el.field === 'project' && el.temp);
        if (existFolderConditionIndex !== -1) {
            filterObj.filter.conditions.splice(existFolderConditionIndex, 1);
        }
    }
}
function addOrReplaceCondition(filterObj, condition) {
    if (filterObj?.filter.operator === 'and') {
        let existFolderConditionIndex = filterObj.filter.conditions.findIndex(el => el.field === condition?.field);
        if (existFolderConditionIndex === -1) {
            filterObj.filter.conditions.push(condition);
        } else {
            filterObj.filter.conditions.splice(existFolderConditionIndex, 1, condition);
        }
    } else {
        let tempConditions = { ...filterObj.filter };
        filterObj.filter = {
            id: uuid.v4(),
            operator: 'and',
            type: 'conjunction',
            conditions: []
        };
        filterObj.filter.conditions.push(tempConditions);
        filterObj.filter.conditions.push(condition);
    }
}
function generateBadgeCondition(priority) {
    let condition = {
        comparator: 'in list',
        field: '#has_badges',
        value: [priority.substring(1)],
        type: 'condition',
        name: `badge + ${priority.substring(1)}`,
        alias: priority.substring(1),
        color: '#c9e061',
        id: 'badges_condition'
    };
    switch (priority) {
        case '@mention':
            condition.color = '#05b187';
            break;
        case '@high':
            condition.color = '#fa896b';
            break;
        case '@normal':
            condition.color = '#ffae1f';
            break;
        case '@approve':
            condition.color = '#1a97f5';
            break;

        default:
            break;
    }
    return condition;
}
export function addAditionalRules(filterObg, route, lang = 'en', ignoreRules = []) {
    const addRulesList = ['folder', 'tags', 'hash', 'favorite'];
    const filteredAddRulesList = addRulesList.filter(item => !ignoreRules.includes(item));
    if (route?.query?.folder && filteredAddRulesList.includes('folder')) {
        addOrReplaceFolderCondition(filterObg, generateFolderCondition(route.query.folder));
    } else {
        removeFolderCondition(filterObg);
    }
    if (route?.query?.tags && filteredAddRulesList.includes('tags')) {
        addOrReplaceTagsCondition(filterObg, generateTagsCondition(route.query.tags));
    } else {
        removeTagsCondition(filterObg);
    }
    if (route?.hash && route?.hash[1] === '@' && filteredAddRulesList.includes('hash')) {
        addOrReplaceBadgeCondition(filterObg?.filter.conditions, generateBadgeCondition(route.hash.substring(1)));
        if (lang === 'en') {
            filterObg.name = 'Need attention';
        } else {
            filterObg.name = 'Потребують уваги';
        }
    }
    let favoriteCondation = {
        field: '#favorites',
        comparator: '=',
        value: 'true',
        type: 'condition',
        id: 'favorites'
    };
    if (route?.query?.filter === 'favorite') {
        addOrReplaceCondition(filterObg, favoriteCondation);
        if (lang === 'en') {
            filterObg.name = 'Favorite';
        } else {
            filterObg.name = 'Обрані';
        }
    } else {
        removeCondition(filterObg, favoriteCondation.id);
    }
    if (route?.hash && route?.hash === '#favorite') {
        if (lang === 'en') {
            filterObg.name = 'Favorite';
        } else {
            filterObg.name = 'Обрані';
        }
    }
    return filterObg;
}
export function calculateActiveFilter(listOfFilters, route, lang) {
    if (!listOfFilters || listOfFilters?.length <= 0) {
        return null;
    }
    let filterObg = null;
    if (route.query?.filter && route.query?.filter !== 'favorite') {
        let filter = findFilter(route.query?.filter, listOfFilters);
        filterObg = filter ? JSON.parse(JSON.stringify(filter)) : generateFilterObg('hidden');
    } else {
        filterObg = generateFilterObg('hidden');
    }
    return addAditionalRules(filterObg, route, lang);
}
export function addCondition(filterObj, newCondition) {
    if (filterObj?.filter.operator === 'and') {
        let existConditionIndex = filterObj.filter.conditions.findIndex(el => el.id === newCondition.id);
        if (existConditionIndex === -1) {
            filterObj.filter.conditions.push(newCondition);
        } else {
            filterObj.filter.conditions.splice(existConditionIndex, 1, newCondition);
        }
    } else {
        let tempConditions = { ...filterObj.filter };
        filterObj.filter = {
            id: uuid.v4(),
            operator: 'and',
            type: 'conjunction',
            conditions: []
        };
        filterObj.filter.conditions.push(tempConditions);
        filterObj.filter.conditions.push(newCondition);
    }
    return filterObj;
}
export function removeCondition(filterObj, conditionId) {
    if (filterObj?.filter && Array.isArray(filterObj.filter?.conditions)) {
        let existConditionIndex = filterObj.filter?.conditions.findIndex(el => el.id === conditionId);
        if (existConditionIndex !== -1) {
            filterObj.filter.conditions.splice(existConditionIndex, 1);
        }
    }
}
export function findCondition(filterObj, conditionId) {
    if (filterObj?.filter && Array.isArray(filterObj.filter?.conditions)) {
        let existCondition = filterObj.filter?.conditions.find(el => el.id === conditionId);
        if (existCondition) {
            return existCondition;
        } else return null;
    }
}
export function findFilterFields(filterset, excludeTempFields = false) {
    let res = {};
    function find(filterSet) {
        if (!Array.isArray(filterSet)) {
            return;
        }
        filterSet.forEach(element => {
            if (element?.type === 'condition') {
                if ((excludeTempFields && element?.temp) || element?.disabled) return;
                else {
                    res[element.field] = element.value;
                }
            } else if (element.type === 'conjunction') {
                find(element?.conditions);
            } else {
                console.log('not find');
            }
        });
    }
    if (filterset) {
        find(filterset);
    }
    return res;
}

export function hasEmptyValue(item) {
    if (Array.isArray(item)) {
        return item.some(subItem => hasEmptyValue(subItem));
    } else if (typeof item === 'object' && item !== null) {
        if ('value' in item && 'comparator' in item && item.comparator !== 'empty' && item.comparator !== 'not empty') {
            if (Array.isArray(item.value) && item.value.length === 0) {
                return true;
            } else {
                return item.value === null || item.value === '';
            }
        } else if (Array.isArray(item.conditions)) {
            if (item.conditions.length === 0) {
                return true;
            }
            return item.conditions.some(subItem => hasEmptyValue(subItem));
        }
    }
    return false;
}

export function hasEmptyEnabledValue(item) {
    if (Array.isArray(item)) {
        return item.some(subItem => this.hasEmptyEnabledValue(subItem));
    } else if (typeof item === 'object' && item !== null) {
        if (item.disabled) {
            return false;
        }
        if ('value' in item && 'comparator' in item && item.comparator !== 'empty' && item.comparator !== 'not empty') {
            if (Array.isArray(item.value) && item.value.length === 0) {
                return true;
            } else {
                return item.value === null || item.value === '';
            }
        } else if (Array.isArray(item.conditions)) {
            if (item.conditions.length === 0) {
                return true;
            }

            return item.conditions.some(subItem => this.hasEmptyEnabledValue(subItem));
        }
    }
    return false;
}

export function hasEmptyComparator(item) {
    if (Array.isArray(item)) {
        return item.some(subItem => hasEmptyComparator(subItem));
    } else if (typeof item === 'object' && item !== null) {
        if ('comparator' in item) {
            return !item.comparator || item.comparator === '';
        } else if (Array.isArray(item.conditions)) {
            if (item.conditions.length === 0) {
                return true;
            }
            return item.conditions.some(subItem => hasEmptyComparator(subItem));
        }
    }
    return false;
}
