<template>
    <Teleport :to="props.teleportTo">
        <div class="dynamic-modal-container" :style="{ zIndex: props.zIndex || componentZIndex }">
            <div
                id="myModal"
                @mousedown="handleMouseDown"
                @mouseup="handleMouseUp"
                class="modal fixed flex left-0 top-0 w-full h-full m-0 overflow-hidden"
                :class="[
                    { 'justify-start': props.horizontalAlign === 'left' },
                    { 'justify-center': props.horizontalAlign === 'center' },
                    { 'justify-end': props.horizontalAlign === 'right' },
                    { 'items-start': props.verticalAlign === 'top' },
                    { 'items-center': props.verticalAlign === 'center' },
                    { 'items-end': props.verticalAlign === 'bottom' },
                    {
                        'bg-[black]/50': !$attrs.class?.includes('bg') && !props.acceptClickUnderModal
                    },
                    {
                        'backdrop-blur-sm': !props.acceptClickUnderModal && props.blurBackgrnd
                    },
                    props.acceptClickUnderModal ? 'pointer-events-none bg-transparent	' : 'pointer-events-auto',
                    $attrs.class
                ]">
                <div
                    class="modal-content pointer-events-auto max-h-full max-w-full bg-white"
                    :class="[
                        props.fullHeight ? 'h-full' : 'h-fit',
                        props.fullWidth ? 'w-full' : 'w-fit',
                        {
                            'scroll-wrap': $attrs.class?.includes('scroll-wrap')
                        },
                        {
                            'overflow-hidden': $attrs.class?.includes('overflow-hidden')
                        },
                        {
                            shadow: props.acceptClickUnderModal
                        },
                        {
                            flex: $attrs.class?.includes('flex')
                        }
                    ]"
                    :style="[
                        { boxShadow: props.boxShadow },
                        { borderTopRightRadius: props.borderRadius && borderRTR ? props.borderRadius : 'none' },
                        { borderTopLeftRadius: props.borderRadius && borderRTL ? props.borderRadius : 'none' },
                        { borderBottomRightRadius: props.borderRadius && borderRBR ? props.borderRadius : 'none' },
                        { borderBottomLeftRadius: props.borderRadius && borderRBL ? props.borderRadius : 'none' }
                    ]">
                    <slot></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import { useAttrs, onMounted, onBeforeUnmount, ref } from 'vue';
import { getMaxZIndexInElement } from '@/helpers';
const $attrs = useAttrs() as { class?: string };

interface Props {
    teleportTo?: string;
    horizontalAlign?: 'left' | 'right' | 'center';
    verticalAlign?: 'top' | 'bottom' | 'center';
    fullHeight?: boolean;
    fullWidth?: boolean;
    closeOnClickOutside?: boolean;
    blurBackgrnd?: boolean;
    zIndex?: number;
    acceptClickUnderModal?: boolean;
    borderRadius?: string;
    boxShadow?: string;
    borderRTL?: boolean;
    borderRTR?: boolean;
    borderRBL?: boolean;
    borderRBR?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    teleportTo: '#app',
    horizontalAlign: 'center',
    verticalAlign: 'center',
    fullHeight: false,
    fullWidth: false,
    closeOnClickOutside: true,
    blurBackgrnd: false,
    acceptClickUnderModal: false,
    borderRadius: 'var(--sp-border-radius)',
    boxShadow: '',
    borderRTL: true,
    borderRTR: true,
    borderRBL: true,
    borderRBR: true
});

const emit = defineEmits<{ closeModalWindow: []; close: []; click: [] }>();

const componentZIndex = ref(props.zIndex || getMaxZIndexInElement(props.teleportTo));

const isMouseDownInsideForm = ref<boolean>(false);
let isDown = false;

function handleMouseDown(event: MouseEvent): void {
    emit('click');
    isDown = true;
    if (event.target === event.currentTarget) {
        isMouseDownInsideForm.value = false;
    } else {
        isMouseDownInsideForm.value = true;
    }
}

const handleMouseUp = (event: MouseEvent): void => {
    if (event.target === event.currentTarget && !isMouseDownInsideForm.value && props.closeOnClickOutside && isDown) {
        emit('closeModalWindow');
        emit('close');
        isDown = false;
    }
};

const handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && props.closeOnClickOutside) {
        // event.stopPropagation();
        emit('closeModalWindow');
        emit('close');
    }
};

onMounted(() => {
    document.addEventListener('keydown', handleKeyPress, true);
});

onBeforeUnmount(() => {
    document.removeEventListener('keydown', handleKeyPress, true);
});
</script>
<style lang="scss" scoped>
.dynamic-modal-container {
    position: fixed;
    inset: 0px;
    pointer-events: none;
    overflow: hidden;
}
</style>
