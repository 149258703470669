<template>
    <div class="flex alert-item-wraper p-2 mt-3" @click="closeAlert" :style="{ zIndex: zIndex }">
        <div class="flex justify-center items-center w-12">
            <svg v-if="item?.type == 'info'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 alert-icon" viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M11 15h2v2h-2v-2m0-8h2v6h-2V7m1-5C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 18a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8Z" />
            </svg>
            <svg v-else-if="item?.type == 'success'" class="h-6 w-6 alert-icon" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
            </svg>
            <svg v-else-if="item?.type == 'warning'" class="h-6 w-6 alert-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 2L1 21h22M12 6l7.53 13H4.47M11 10v4h2v-4m-2 6v2h2v-2" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 alert-icon" viewBox="0 0 24 24">
                <path
                    fill="currentColor"
                    d="M11 15h2v2h-2v-2m0-8h2v6h-2V7m1-5C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 18a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8Z" />
            </svg>
        </div>

        <div class="flex px-2">
            <div class="mx-3">
                <span v-if="item.title" class="alert-color font-semibold">{{ item.title }}</span>
                <p class="alert-color mb-0" v-html="item.text"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { getMaxZIndexInElement } from '@/helpers';
export default {
    name: 'AlertItem',
    data() {
        return {
            zIndex: 0
        };
    },
    components: {},
    props: {
        item: {
            type: Object
        },
        close: {
            type: Function
        }
    },
    emits: ['close'],
    computed: {
        borderColor() {
            switch (this.item?.type) {
                case 'success':
                    return '#05b187';
                case 'warning':
                    return '#ffae1f';
                case 'error':
                    return '#fa896b';
                case 'info':
                    return '#539bff';
                default:
                    return '#000';
            }
        }
    },
    methods: {
        closeAlert() {
            this.close(this.item?.id);
            console.log('close');
        }
    },
    mounted() {
        this.zIndex = getMaxZIndexInElement() + 1;
    }
};
</script>

<style lang="scss">
.alert-item-wraper {
    background-color: #fff;
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: v-bind(borderColor);
    cursor: pointer;
    // box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.alert-color {
    color: v-bind(borderColor);
}

.alert-icon {
    fill: v-bind(borderColor);
    color: v-bind(borderColor);
    color path {
        fill: v-bind(borderColor);
        color: v-bind(borderColor);
    }
}
</style>
