<template>
    <div ref="containerForButton" class="header-bug-report relative overflow-hidden">
        <BaseButton color="third" variant="text" size="sm" @click="showBugForm" v-tooltip:bottom="$t('bugForm.createAnIssue')">
            <template #icon>
                <BaseIcon name="bug" class="h-[24px] w-[24px]"></BaseIcon>
            </template>
        </BaseButton>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue';

const instance = getCurrentInstance();
const modal = instance.appContext.config.globalProperties.$modal;
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const containerForButton = ref(null);

function showBugForm() {
    const rect = containerForButton.value.getBoundingClientRect();
    const params = {
        id: 'BugReportForm',
        componentTitle: t('bugForm.createAnIssue'),
        name: 'BugReportForm',
        props: {},
        stick: {
            minw: 490,
            resizable: false,
            width: 490,
            left: rect.left - 368,
            top: 56,
            isNotModalFullSize: true
        }
    };
    modal.open(params);
}
</script>
