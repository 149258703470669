<template>
    <nav-button
        v-if="subEntities.length && entityId && instanceId"
        :items="menuItems"
        class="nav-btn modal-top-menu-icons cursor-auto my-2"
        @nav-btn-click="navBtnClick"
        :disabled="false"
        :showBreadcrumb="true">
        <ContextMenu v-if="createSubEntityVisible" :event="createSubEntityEvent" :items="subEntitiesList" @changedValue="subEntityMenuClick" @closeContextMenu="closeSubEntityMenu">
        </ContextMenu>
    </nav-button>
</template>

<script>
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import NavButton from '@/components/platformSlider/NavButton.vue';
import BasedEntityApi from '@/api/basedEntity';
import { mapActions, mapState } from 'pinia';
import { useEntitysStore } from '@/stores/entityStore';
import { useUserStore } from '@/stores/userStore';

export default {
    name: 'CreateBasedEntityMenu',
    components: { NavButton, ContextMenu },
    props: {
        entityId: {
            type: String
        },
        instanceId: {
            type: String
        }
    },
    data() {
        return {
            createSubEntityVisible: false,
            createSubEntityEvent: null,
            subEntities: [],
            menuItems: [
                {
                    id: 'subEntitiesMenu',
                    label: this.$t('menu.createBasedEntity'),
                    icon: 'arrow_down',
                    active: false,
                    viewBox: '0 0 24 24',
                    tooltip: this.$t('menu.basedEntityDescription'),
                    hide: true
                }
            ]
        };
    },
    computed: {
        ...mapState(useUserStore, ['currentLocale']),
        subEntitiesList() {
            return this.subEntities.map(subEntity => ({
                id: subEntity.id,
                label: subEntity?.translation?.[this.currentLocale] ?? subEntity.name,
                type: 'button',
                icon: 'cursor-in-window'
            }));
        }
    },
    methods: {
        ...mapActions(useEntitysStore, ['getBasedEntities']),
        navBtnClick(e, event) {
            switch (e) {
                case 'subEntitiesMenu':
                    this.$emit('btnClick', e);
                    this.openSubEntityMenu(event);
                    break;
                default:
                    this.submenuClick(e);
                    break;
            }
        },
        async getSubEntities() {
            if (!this.entityId) {
                return;
            }
            let subEntities = JSON.parse(JSON.stringify(await this.getBasedEntities(this.entityId)));
            if (this.instanceId && subEntities.some(subEntity => subEntity.filters && subEntity.filters.length > 0)) {
                subEntities = await BasedEntityApi.getEntityBasedGenerations(this.entityId, this.instanceId);
            }
            if (subEntities) {
                this.subEntities = subEntities;
            }
        },
        async subEntityMenuClick(id) {
            if (id !== 'close') {
                this.$emit('btnClick', id);
                this.closeSubEntityMenu();
                await this.openNewInstanceForm(id?.id);
            }
        },
        async openSubEntityMenu(event) {
            if (this.createSubEntityVisible) {
                this.createSubEntityVisible = false;
            } else {
                this.createSubEntityEvent = event;
                this.createSubEntityVisible = true;
            }
        },
        closeSubEntityMenu() {
            this.createSubEntityVisible = false;
        },
        async openNewInstanceForm(subEntityId) {
            if (!subEntityId) return;
            const subEntity = this.subEntities.find(subEntity => subEntity.id === subEntityId);
            const subInstanceData = await BasedEntityApi.getEntityBasedGenerationData(this.entityId, this.instanceId, subEntity.entityId);
            const params = {
                // id: taskId,
                entityId: subEntity.entityId,
                name: subEntity.component,
                props: {
                    entityId: subEntity.entityId,
                    instanceObjData: subInstanceData
                },
                modalProps: this.getModalProps(subEntity.component),
                stick: {
                    zIndex: 1000
                }
            };
            this.$modal.open(params);
        },
        getModalProps(componentId) {
            switch (componentId) {
                case 'CreateShortTask':
                    return {
                        horizontalAlign: 'right',
                        verticalAlign: 'center',
                        class: 'p-4',
                        fullHeight: true
                    };
                default:
                    return {};
            }
        }
    },
    async created() {
        await this.getSubEntities();
    }
};
</script>

<style scoped>
.topmenu-container {
    border-bottom: 1px solid rgb(229, 231, 235);
}
.modal-top-menu-icons {
    margin: 0 !important;
    height: 35px !important;
}
</style>
