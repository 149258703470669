<template>
    <div v-if="modelValue">
        <VueDragResize
            class="bg-white custom-class"
            :class="{
                'rounded-[10px]': addedRadius && !isMobile
            }"
            :w="rect.width"
            :h="rect.height"
            :x="rect.left"
            :y="rect.top"
            :parentW="wrapperSize.width"
            :parentH="wrapperSize.height"
            :axis="rect.axis"
            :isActive="rect.active"
            :minw="rect.minw"
            :minh="rect.minh"
            :isDraggable="rect.draggable"
            :isResizable="rect.resizable"
            :parentLimitation="rect.parentLim"
            :snapToGrid="rect.snapToGrid"
            :gridX="gridX"
            :gridY="gridY"
            :aspectRatio="rect.aspectRatio"
            :z="rect.zIndex"
            :contentClass="rect.class"
            :stickSize="rect.stickSize"
            @activated="activateEv"
            @deactivated="deactivateEv"
            @dragging="changeDimensions($event)"
            @resizing="changeDimensions($event)"
            @componentApi="methods => $emit('componentApi', methods)">
            <template #header>
                <slot name="header"></slot>
            </template>
            <slot></slot>
        </VueDragResize>
    </div>
</template>
<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';

import VueDragResize from '@/components/ui/baseModalDragResize/components/VueDragResize.vue';

export default {
    name: 'BaseModalDragResize',
    components: { VueDragResize },
    emits: ['changeDimensions', 'activateEv', 'deactivateEv', 'componentApi'],
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        rect: {
            type: Object,
            default: () => ({})
        },
        wrapperSize: {
            type: Object,
            default: () => ({
                width: 0,
                height: 0
            })
        },
        gridX: {
            type: Number,
            default: 100
        },
        gridY: {
            type: Number,
            default: 100
        }
    },
    methods: {
        activateEv() {
            this.$emit('activateEv');
        },
        deactivateEv() {
            this.$emit('deactivateEv');
        },
        changeDimensions(event) {
            this.$emit('changeDimensions', event);
        }
    },
    computed: {
        ...mapState(useUserStore, ['isMobile']),
        addedRadius() {
            return !!this.rect?.radius;
        }
    }
};
</script>

<style scoped>
.custom-class {
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 60px 0px #686c6f3d;
    -moz-box-shadow: 0px 0px 60px 0px #686c6f3d;
    box-shadow: 0px 0px 60px 0px #686c6f3d;

    overflow: hidden;
}
</style>
